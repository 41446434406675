.custom-font2 {
    box-shadow: 0 4px 8px #0000001a, 0 6px 20px #0000001a;
    color: #4d4d4d;
    font-family: Oswald, sans-serif;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 46px;
    margin-top:16vh;
    padding: 0;
    text-align: center;
    text-decoration: none;
}
.services-section{
    width: 85%;
    display:flex;
    justify-content:space-between;
    margin :75px auto;
    text-align:center;
    background:#eee;
}
.card{
    width:100%;
    display:flex;
    align-items:center;
    flex-direction:column;
    margin:0px 20px;
    padding:20px 10px;
    background-color:#fff;
    border-radius:10px;
}
.card:hover{
    background-color:#f3f3f3;
    transition:0.5s ease;
}
.card .icon{
    font-size:45px;
    margin-bottom:25px;
    color:#c78585; 
}

.card h2{
    font-size:22px;
    color:gray;
    margin-bottom:20px;
}
.card p{
    font-size:16px;
    margin-bottom:30px;
    line-height:1.5;
    color:#8c8c8c;
}
.card .button{
    font-size:17px;
    text-decoration:none;
    color:#fff;
    background-color:#c78585;
    padding:11px 15px;
    border-radius:5px;
    margin-bottom:6px;
}
.button:hover{
    background-color:#7cbfcc;
    transition: 1s ease;
}
@media screen and (max-width:1200px){
    .services-section{
        display:flex;
        flex-direction:column;
        text-align:center;       
    }
    .card{
        margin-left:auto;
        margin-right:auto;
        width:70%;
        margin-top:3rem;
    }
}