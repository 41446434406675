@tailwind base;
@tailwind components;
@tailwind utilities;


.container{
  max-width:1220px;
  margin-inline:1.5rem;
}

.header{
  width:100%;
  background-color:#c0c9d3;
  box-shadow : 0 2px 16px hsla(220,32%,8%,0.3);
  z-index:100;
  
}
.nav{
  height:90px;
}
.nav__data{
  height:100%;
}
.nav__logo{
  display:inline-flex;
  align-items:center;
  column-gap:.25rem;
}
.nav__toggle{
  position:relative;
  right:90px;
  top:20px;
  width:32px;
  height:32px;
  
  /* background-color:#002B29 */
}
.nav__burger,
.nav__close{
  position:absolute;
  width:max-content;
  height:max-content;
  inset:0;
  margin:auto;
  font-size:1.25rem;
  cursor:pointer;
   color:#002B29;
  transition:opacity .1s, transform .4s;
  /* border: 1px solid rgb(43, 31, 31); */
  font-size:30px;
}
.nav__close{
  opacity:0;
}

                /* NAVİGATİON FOR MOBİLE  */

                @media screen and (max-width:1118px){
                  .nav__menu{
                    position:absolute;
                    left:0;
                    top:2.5rem;
                    width:100%;
                    
                    height:calc(100vh-3.5rem);
                    overflow:auto;
                    pointer-events:none;
                    opacity:0;
                    transition: top .4s, opacity .3s;
                  }
                .nav__menu::-webkit-scrollbar{
                  width:0;
                }
                .nav__list{
                  background-color:gray;
                  /* padding-top:1rem; */
                  margin-top:1rem;
                }                 
                }
                .nav__link{
                  transition:background-color 1s;
                  align-items:center;
                
                  display:flex;
                  padding:1.25rem 1.5rem;
                  font-weight: bold;
                  color:#002B29;
                  background-color:gray
           
                }
                .nav__link:hover{
                  background-color:rgba(0, 0, 0, 0.603);
                }
                .show-menu{
                  opacity:1;
                  top:3.5rem;
                  pointer-events:initial;
                }
                .show-icon .nav__burger{
                  opacity:0;
                  transform:rotate(90deg);
                }
                .show-icon .nav__close{
                  opacity:1;
                  transform:rotate(90deg);
                }

                              /* DROPDOWN  */

              .dropdown__item{
                cursor:pointer;
              }
              .dropdown__arrow{
                font-size:1.25rem;
                font-weight:initial;
                transition: transform .4s;
              }
              .dropdown__link,
              .dropdown__sublink{
                align-items:center;
                column-gap:.5rem;
                display:flex;
                padding:1.25rem 1.25rem 1.25rem 2.5rem;
                color:white;
                background-color:#002B29;
                font-weight:500;
                transition:background-color 0.7s;          
              }
              
              .dropdown__link:hover,
              .dropdown__sublink:hover{
                background-color:#002B29;
              }
              .dropdown__menu,
              .dropdown__submenu{
                max-height:0;
                overflow:hidden;
                transition:max-height .9s ease-out;
              }
              /* SHOW DROP DOWN */
              .dropdown__item:hover .dropdown__menu,
              .dropdown__subitem:hover .dropdown__submenu{
                max-height:1000px;
                transition:max-height .7s ease-in
              }

              /* ROTATE DROPDOWN ITEM */
              .dropdown__item:hover .dropdown__arrow{
                transform:rotate(180deg);
              }
     

              /* DROPDOWN SUB MENU */
              .dropdown__add{
                margin-left:auto;
              }
              .dropdown__sublink{
                background-color:rgba(0, 0, 0, 0.349);
              }

              /* ForSMALL DEVICES */
                @media screen and (max-width:340px){
                  .container{
                    margin-inline:1rem;
                  }
                  .nav__link{
                    padding-inline:1rem;
                  }
                }
                /* For Large DEVICES */
                @media screen and (min-width:1118px){
                  .container{
                    margin-inline:auto;
                  }
                  .nav{
                    /* height:calc() */
                    display:flex;
                    justify-content:space-between
                  }
                  .nav__list{
                    height:100%;
                    display:flex;
                    column-gap:1rem;    
                   margin-top: -5px;                
                  }              
                  .nav__link {
                    background-color:transparent!important;             
                  }
                  .nav__link li {
                    background-color:transparent!important;   
                  }
                  .nav__toggle{
                    display:none;
                  }

                }




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width:250px){
  .nav__burger, .nav__close {
    color: white;
  }

}
