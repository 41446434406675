/* .slider{
    width:80vw;
    max-width:100vw;
    height:35vh;
    margin:auto;
    position:relative;
}
.list{
    position:absolute;
    top:200px;
    left:0;
    height:100%;
    display:flex;
}
.list img{
    width:80vw;
    max-width:100vw;
    height:45vh;
    object-fit:cover;
}
.buttons{
    position:absolute;
    top:50%;   
    display:flex;
    justify-content:space-between;
    width:20px;
    
    
}

.buttons button{
    position:absolute;   
    width:50px;
    height:50px;  
    
}


 */
 .slider {
    width: 80vw;
    max-width: 100vw;
    height: 45vh;
    margin: auto;
    position: relative ;
    top:15vh;
    overflow: hidden; /* Added to hide overflow */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Added box-shadow for border shadowing */
  border-radius: 10px; /* Optional: to add rounded corners */
  }
  
  .list {
    position: absolute;
    top: 0; /* Changed to 0 to align items within the slider */
    left: 0;
    height: 100%;
    display: flex;
    transition: left 0.5s ease; /* Added for smooth sliding */
  }
  
  .list img {
    width: 80vw;
    max-width: 100vw;
    height: 100%; /* Adjusted to 100% to fit the container */
    object-fit: cover;
  }
  
  .buttons {
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: space-between;
    width: 100%; /* Changed to 100% to span the slider width */
    transform: translateY(-50%); /* Center buttons vertically */
  }
  
  .buttons button {
    width: 50px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.4s ease, transform 0.4s ease;

  }
  .buttons button:hover{
    background-color:transparent;
    transform: scale(1.7);
  }
  

