* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

 /* 
 .image-section-about::before {
    content: '';
    position: absolute;
    border: 15px solid #1D4ED8;  Tailwind's blue-700 color 
    width: 400px;
    height: 500px;
    top: 80px;
    left: -40px;
    z-index: 1;
    background-color: white;
} */
 .element-section{
    position:relative;
    padding: 80px 0px 50px 0px;
 }
 .element-container{
    max-width:1140px;
    display:flex;
    margin-right:auto;
    margin-left:auto;
 }
 .element-column{
    width:100%;
    position:relative;
    display:flex;
    
    
 }
 .element-widget-wrap{
    padding:10px;
    display:flex;
    position:relative;
    align-content:flex-start;
 }
 @media (min-width: 1200px) {
    .element-widget-container {
        max-width: 1140px;
    }
}

.row {
 
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
@media (min-width: 768px) {
    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (min-width: 992px) {
    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}
.image-colarge{
    position: relative;
    height: 100%;
    margin-bottom: 80px;
}
.colarge-1 {
    position: relative;
    margin: 40px 30px 0px 40px;
    z-index: 1;
}
.colarge-1:before {
    left: -40px;
    top: -40px;
}
.colarge-1:before {
    position: absolute;
    border: 15px solid #1c63b8;
    content: '';
    width: 80%;
    height: 80%;
    z-index: -1;
}
.slide-righ{
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
}
.since-year-outer {
    position: relative;
}
.since-year {
    position: absolute;
    right: 0px;
    top: -30px;
    z-index: 1;
}
.since-year span {
    color: #191919;
    font-size: 70px;
    font-weight: 800;
    font-family: 'Teko', sans-serif;
    display: inline-block;
    line-height: 0px;
    position: relative;
}
.since-year strong {
    color: #1c63b8;
    font-size: 70px;
    font-weight: 600;  
    display: inline-block;
    background: none !important;
    padding: 90px 30px 20px 0px;
    line-height: 0px;
    font-family: 'Teko', sans-serif;
}
.welcome-to-section{
    float: left;
    width: 100%;
}
.sep-leaf-left {
    position: relative;
    margin-right: 50px;
    display: none;
    box-sizing:border-box;
}
.wt-small-separator div {
    display: inline-block;
}
.sep-leaf-left:before {
    position: absolute;
    content: '';
    background-color: #1c63b8;
    width: 40px;
    height: 3px;
    top:15px;
    right:-50px;
}
.bh2{
    margin-bottom: 15px;
    font-size: 52px;
    font-weight: 600;
    line-height: 1.1;
    font-family: 'Teko', sans-serif;
}
.bh2 span{
    font-family: 'Teko', sans-serif;
    font-weight: 600;
    line-height: 1.1;
    color: #1c63b8;
}
.bp{
    margin-bottom: 25px;
    line-height: 28px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #272727;
}
.checklist{
    margin: 0;
    list-style: none;
 float:left;
   
 
    margin-bottom: 30px;
}
.checklist2{
    margin: 0;
    list-style: none;
    float: right;  
    margin-bottom: 30px;
}


@media (min-width: 768px) {
    .element-column{
        width: 100%;
    }
}

@media only screen and (max-width: 991px) {
    .img-colarge {
        padding-top: 30px;
        margin: -10px;
    }
}
@media (min-width: 768px) {
    .about-container {
        max-width: 720px;
     
    }
}
@media (min-width: 576px) {
    .about-container {
        max-width: 540px;
    }
}
@media (min-width: 992px) {
    .about-container {
        max-width: 960px;
    }
}
@media (max-width: 992px) {
    .element-column {
        place-items: center;
        margin-left: 8%;
        margin-right:8%;
        text-align: center;
    }
    .element-column{
        margin-left:15%;
    }
}
@media only screen and (max-width: 480px) {
    .since-year span {
        font-size: 35px;
    }
    .since-year strong {
        font-size: 35px;
    }
    .sep-leaf-left:before {
        visibility: hidden;
    }
    .bh2{
        font-size:35px;
    }
    .bp{
        font-size:17px;
    }
    
}
.elcustom{
    box-shadow: 0 4px 8px #0000001a, 0 6px 20px #0000001a;
    color: #4d4d4d;
    font-family: Oswald, sans-serif;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 46px;
    margin-top: 2vh;
    padding: 0;
    text-align: center;
    text-decoration: none;
    background-color:whitesmoke
}
