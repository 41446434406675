.custom-font3 {
    box-shadow: 0 4px 8px #0000001a, 0 6px 20px #0000001a;
    color: #4d4d4d;
    font-family: Oswald, sans-serif;
    font-size: 22px;
    font-style: italic;
    font-weight: 400;
    line-height: 46px;
    margin-top:18vh;
    padding: 0;
    text-align: center;
    text-decoration: none;
    margin-bottom:3vh;
  
}

