.custom-font {
  font-family: 'Oswald', sans-serif;
  font-size: 22px;
  line-height: 46px;
  font-style: italic;
  font-weight: 400;
  color: rgb(77, 77, 77);
  text-decoration: none;
  padding: 0px;
  margin: 0px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);   
}

.small-image {
  z-index: 1;
  width: 80%; /* You can adjust the width as per your requirement */
height:130px;
   /* Maintains aspect ratio */
  margin: 0 3rem; /* Adds some space between images */
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4); /* Shadow effect */
}

.small-image:hover {
  transform: translateY(-25px); /* Lift-up effect on hover */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4); /* Increase shadow on hover */
}

.gear-wrapper {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity and color as needed */
  border-radius: 50%; /* This will make it a circle, adjust for rounded edges */
  padding: 10px; /* Adjust as needed */
  margin: 0 auto; 
  z-index: 2; /* Set a higher z-index */
}
.gear-wrapperi {
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity and color as needed */
  border-radius: 50%; /* This will make it a circle, adjust for rounded edges */
  padding: 10px; /* Adjust as needed */
  margin: 0 auto; 
  z-index: 2; /* Set a higher z-index */
}

.gear-icon {
  font-size: 36px; /* Adjust the size as needed */
  color: white; /* Change the color to match your design */
  z-index: 10; /* Set a higher z-index */
}

@media (max-width: 1200px) {
  #container {
    flex-wrap: wrap;
    
  }
  .gear-icon {
    font-size: 24px;
  }
  
  .w-fulli {
    width: 60%;
    object-fit: cover;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:12vh;

  }
  .mini-container {
    position: relative;
    align-self:center;
    border-radius: 8px; /* Add border radius for rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow */
    margin-top: 2rem;
  }
  h2{
    margin-left:4vw!important;
    margin-right:6vw!important;
  }
}
@media (min-width: 1200px) {
  .gear-wrapperi {
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity and color as needed */
    border-radius: 50%; /* This will make it a circle, adjust for rounded edges */
    padding: 10px; /* Adjust as needed */
    margin: 0 auto; 
    z-index: 2; /* Set a higher z-index */
  }
}
