.input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px; /* Add border radius */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add box shadow */
  }
  
  .submit-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px; /* Add border radius */
    padding: 10px 20px;

    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Add box shadow */
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }

  /* Media query for screen width larger than 1200px */
@media (min-width: 1100px) {
    .input-field {
      width: 50%; /* Half of the available width minus margins */
      margin-right: 20px; /* Add some spacing between fields */
    } 
        
         .submit-button{
            position:absolute;
            bottom:30%;
            left:45%;
          }
          
  
    .input-field:last-child {
      margin-right: 0; /* Remove margin from the last input field */
    }
  }